import React, {Component} from "react";
import styled from "styled-components";
import {G_GRAY} from "../../style/variables";

const charming = require("charming");

const ItemLink = styled.button`
  border: 0;
  background: transparent;
  display: block;
  padding: 10px 0;
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-size: 28px;
  color: ${G_GRAY};
  text-decoration: none;
  overflow: hidden;
  margin: 0 auto;
  outline: 0;
  span {
    display: inline-block;
    white-space: pre;
  }
  @media all and (min-width : 768px) {
    font-size: 64px;
  }
`;

export default class WorkItem extends Component {
  
  componentDidMount() {
    const $item = document.querySelector(`.work-item__${this.props.index}`);
    charming($item);
  }
  
  render() {
    return (
      <ItemLink
        className={`work-item work-item__${this.props.index}`}
        onClick={() => (this.props.navigateTo(this.props.route))}>
        {/*to={this.props.route}>*/}
        {this.props.title}
      </ItemLink>
    );
  }
  
}