import React from "react"

const ImgSrcSet = ({name, pictureClassName = ""}) => (
  <picture className={pictureClassName}>
    <source
      media="(min-width: 900px)"
      srcSet={`${name}-lg_1x.webp 1x, ${name}-lg_2x.webp 2x`}
      type="image/webp"/>
    <source
      media="(min-width: 601px)"
      srcSet={`${name}-md_1x.webp 1x, ${name}-md_2x.webp 2x`}
      type="image/webp"/>
    <source
      srcSet={`${name}-sm_1x.webp 1x, ${name}-sm_2x.webp 2x`}
      type="image/webp"/>
    <img
      srcSet={`${name}-sm_1x.jpg 600w, ${name}-md_1x.jpg 900w, ${name}-lg_1x.jpg 1440w`}
      src={`${name}-lg_1x.jpg`}
      type="image/jpeg"
      alt=""/>
  </picture>
)

export default ImgSrcSet