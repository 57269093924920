import React, {Component} from "react"
import styled from "styled-components"
import withMountAnimation from "../../components/hoc/workPageHoc"
import Menu from "../../components/globals/menu"
import {Container, ImgContainer, Img100} from "../../components/pages/work-page/container"
import Title from "../../components/pages/work-page/title"
import Info from "../../components/pages/work-page/info"
import Breadcrumb from "../../components/pages/work-page/breadcrumb"
import Paragraph from "../../components/pages/work-page/paragraph"
import ImgSrcSet from "../../components/pages/work-page/image"
import BigLink from "../../components/pages/work-page/biglink"
import Footer from "../../components/pages/work-page/footer"
import {DATA__INTERTWINE} from "./workList"
import VideoPlayer from "../../components/pages/work-page/video"

const infos = [
  {
    "title": "website",
    "description": "intertwine.it",
    "href": "https://intertwine.it",
    "type": "anchor"
  }, {
    "title": "role",
    "description": "founder, frontend lead dev."
  }
]

const VideoContainer = styled.div`
  position: relative;
  max-width: 100%;
  width: 960px;
  height: 280px;
  @media all and (min-width: 768px) {
    height: 500px;
  }
  margin: 0 auto 50px;
`

class Intertwine extends Component {
  
  render() {
    return (
      <>
        <Breadcrumb currentRoute={"intertwine"}/>
        <Menu/>
        <Container>
          <Title
            title={DATA__INTERTWINE["title"]}
            caption={DATA__INTERTWINE["caption"]}/>
          <Info data={infos}/>
          <Paragraph
            title={"Overview"}
            body={`I co-founded and developed the frontend of a platform that allows people to write solo or collaborative stories, in 2019 we count 220k users, ~1.2M in investment and 10 people in the team.
                <br />Right now is the greatest community of storytellers in Italy`}/>
          <ImgContainer>
            <ImgSrcSet name={"/resources/images/work/intertwine/intertwine-logo"}/>
          </ImgContainer>
          <Paragraph
            marginTop={100}
            marginBottom={50}
            title={"Core features - Frontend Development"}
            body={`I took care of the whole frontend architecture using technologies like SASS, Gulp, Twig, Backbone, CKEditor and many more.
            The core feature of Intertwine is its WYSIWYG editor that allows people to create stories using text, images, videos and embeds`}/>
          <VideoContainer>
            <VideoPlayer
              poster={""}
              sources={[{
                src: '/resources/images/work/intertwine/intertwine-editor.mp4',
                type: 'video/mp4'
              }]}/>
          </VideoContainer>
          <Paragraph
            marginTop={100}
            marginBottom={50}
            title={"Intertwine App - Mobile Development"}
            body={`Developed entirely in React Native, the Intertwine application is a Tinder for Stories. It allows to browse the community stories via a swipe system. Published on both App Store and Play Store`}/>
          <Img100>
            <ImgSrcSet name={"/resources/images/work/intertwine/intertwine-app"}/>
          </Img100>
          <BigLink
            marginVertical={60}
            link={"https://intertwine.it"}/>
          <Footer prevRoute={"designflows"} nextRoute={"otto"}/>
        </Container>
      </>
    )
  }
  
}

export default withMountAnimation(Intertwine)