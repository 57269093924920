import React, {Component} from "react"
import styled from "styled-components"
import {ThinHeading1, MediumSpan} from "../components/globals/typography"
import Rect from "../components/pages/home/rect"
import Menu from "../components/globals/menu"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const CopyContainer = styled.div`
  width: 930px;
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Copy = styled(ThinHeading1)`
  position: relative;
  color: #d6d6d6;
  mix-blend-mode: difference;
  font-size: 42px;
  z-index: 1;
  @media all and (min-width : 768px) {
    font-size: 70px;
  }
`

export default class Home extends Component {
  
  componentDidMount() {
    document.body.style.overflow = "hidden"
  }
  
  componentWillUnmount() {
    document.body.style.overflow = ""
  }
  
  render() {
    return (
      <>
        <Menu/>
        <Container>
          <CopyContainer>
            <Copy>
              Hello, I’m Stefano
              <br/>
              <br/>
              I <MediumSpan>think</MediumSpan>, <MediumSpan>design</MediumSpan> and <MediumSpan>develop</MediumSpan>
              <br/>
              digital experiences
            </Copy>
            <Rect/>
          </CopyContainer>
        </Container>
      </>
    )
  }
  
}