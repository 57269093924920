import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import styled from "styled-components"
import Divider from "../components/globals/divider"
import {G_GRAY} from "../components/style/variables"

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  margin: auto;
`

const Link = styled(NavLink)`
  font-weight: 500;
  font-size: 24px;
  @media all and (min-width : 768px) {
    font-size: 48px;
  }
  color: ${G_GRAY};
  text-decoration: none;
  text-transform: uppercase;
`

const CloseButton = styled.button`
  font-family: "Manrope", sans-serif !important;
  position: absolute;
  top: 10px;
  right: 20px;
  outline: 0;
  border: 0;
  background: transparent;
  font-weight: 200;
  font-size: 60px;
  color: ${G_GRAY};
  transform: rotate(45deg);
`

const activeStyle = {
  textDecoration: "line-through"
}

export default class MenuPage extends Component {
  
  render() {
    return (
      <Container>
        <CloseButton
          onClick={this.props.closeModal}>
          {/*&lt;-*/}
          +
        </CloseButton>
        <Nav>
          <Link exact activeStyle={activeStyle} to="/">Home</Link>
          <Divider/>
          <Link exact activeStyle={activeStyle} to="/work">Work</Link>
          <Divider/>
          <Link exact activeStyle={activeStyle} to="/about">About</Link>
        </Nav>
      </Container>
    )
  }
  
}