import React from "react"
import styled from "styled-components"
import {G_GRAY} from "../../style/variables"

const Container = styled.div`
  text-align: center;
  color: ${G_GRAY};
  max-width: 600px;
  @media all and (min-width : 768px) {
    min-width: 600px;
  }
  margin-right: auto;
  margin-left: auto;
  margin-top: ${props => props.marginTop ? `${props.marginTop}px` : 0};
  margin-bottom: ${props => props.marginBottom ? `${props.marginBottom}px` : 0};
`

const Title = styled.h3`
  font-weight: 900;
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 20px;
`

const ParagraphText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  padding: 0 10px;
`

const Paragraph = (props) => (
  <Container {...props}>
    <Title>{props.title}</Title>
    <ParagraphText dangerouslySetInnerHTML={{__html: props.body}}/>
  </Container>
)

export default Paragraph