import React from "react"
import styled from "styled-components"
import {Anchor} from "../../globals/anchor"
import {G_GRAY} from "../../style/variables"

const Container = styled.div`
  width: 100%;
  text-align: center;
`

const BigAnchor = styled(Anchor)`
  display: block;
  margin: ${props => props.marginVertical ? `${props.marginVertical}px` : "0"} auto;
  border: 1px solid ${G_GRAY};
  padding: 30px 0;
  text-align: center;
  width: 300px;
  &:hover {
    background-color: ${G_GRAY};
    color: white !important;
  }
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: underline;
`

const BigLink = (props) => (
  <Container>
    <BigAnchor
      {...props}
      target={"_blank"}
      href={props.link}>
      {props.text}
    </BigAnchor>
  </Container>
)

BigLink.defaultProps = {
  text: "Visit website"
}

export default BigLink