import React, {Component} from 'react'
// import styled from "styled-components"
import videojs from 'video.js'
// import "video.js/dist/video-js.min.css"
import "../../../style/video.scss"


class VideoPlayer extends Component {
  
  constructor(props) {
    super(props)
    
    // this.videoNode = React.createRef()
    this.state = {isPlaying: false}
  }
  
  componentDidMount() {
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      // console.log('onPlayerReady', this)
    })
    
    this.player.on(['waiting', 'pause'], () => {
      this.setState({isPlaying: false})
    })
    
    this.player.on('playing', () => {
      this.setState({isPlaying: true})
    })
    
    this.player.on('dblclick', () => {
      this.videoNode.requestFullscreen()
    })
  }
  
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }
  
  play = () => {
    this.videoNode.play()
  }
  
  pause = () => {
    this.videoNode.pause()
  }
  
  toggle = () => {
    // this.isPlaying ? this.pause() : this.play()
    this.state.isPlaying ? this.pause() : this.play()
  }
  
  render() {
    return (
      <>
        <div className='video-js--container'>
          <div data-vjs-player>
            <video
              onClick={this.toggle}
              ref={(ref) => this.videoNode = ref}
              className="video-js"/>
          </div>
          <span
            onClick={this.toggle}
            className={this.state.isPlaying ? "pause" : "play"}>{this.state.isPlaying ? "" : ""}</span>
          <div className="video-js--buttons-container">
            <span
              onClick={() => (this.videoNode.requestFullscreen())}
              className="fullscreen">fullscreen</span>
          </div>
        </div>
      </>
    )
  }
}

VideoPlayer.defaultProps = {
  autoplay: false,
  controls: false,
  preload: true,
  // userActions: {
  //   doubleClick: () => (
  //     this.videoNode.requestFullscreen()
  //   )
  // },
  poster: "/resources/images/work/otto/otto-poster.jpg",
  sources: [{
    src: '/resources/images/work/otto/video.mp4',
    type: 'video/mp4'
  }]
}

export default VideoPlayer