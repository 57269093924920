import React, {useEffect} from "react"
import {TweenMax, Power3} from "TweenMax"
import {WORK_PAGE_ANIMATION_DURATION} from "../constants"

export default function withMountAnimation(WrappedComponent) {
  
  // return class extends Component {
  //   componentDidMount() {
  //     TweenMax.fromTo(".work--container", 0.8, {y: 300, opacity: 0}, {y: 0, opacity: 1, ease: Power3.easeInOut})
  //   }
  //
  //   render() {
  //     return <WrappedComponent />
  //   }
  // }
  
  let tween = null
  
  return () => {
    useEffect(() => {
      tween = TweenMax.fromTo(".work--container", WORK_PAGE_ANIMATION_DURATION, {y: 300, opacity: 0}, {y: 0, opacity: 1, ease: Power3.easeInOut})
      
      return () => {
        tween.kill()
      }
    })
    
    return <WrappedComponent/>
  }
  
  
}

