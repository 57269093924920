import React, {Component} from "react"
import styled from "styled-components"
import {TweenMax, TimelineMax, Circ, Power3} from "TweenMax"
import ScrollMagic from "scrollmagic"
import "debug.addIndicators"

import WorkItem from "../components/pages/work/workItem"
import Divider from "../components/globals/divider"
import Menu from "../components/globals/menu"
import Breadcrumb from "../components/pages/work-page/breadcrumb"
import {L_GRAY} from "../components/style/variables"

const workItems = [
  {
    route: "/work/intertwine",
    title: "intertwine"
  },
  {
    route: "/work/otto",
    title: "otto-exp"
  },
  {
    route: "/work/whatdidyoudoyesterday",
    title: "whatdidyoudoyesterday"
  },
  {
    route: "/work/designflows",
    title: "designflows"
  }
]


const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  @media all and (min-width : 768px) {
    padding: 120px 0;
  }
`

const ItemContainer = styled.section`
  width: 100%;
  text-align: center;
`

const TransitionOverlay = styled.div`
  position: absolute;
  //top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${L_GRAY};
  z-index: 10;
`

export default class Work extends Component {
  
  constructor(props) {
    super(props)
    
    this.controller = new ScrollMagic.Controller()
    this.timeline = new TimelineMax({paused: true})
  }
  
  componentDidMount() {
    const items = document.querySelectorAll(".work-item")
    const docHeight = document.documentElement.offsetHeight
    console.log(docHeight)
    // this.timeline.add(TweenMax.fromTo(this.transitionRef, 1, {height: 0}, {height: docHeight, ease: Power3.easeInOut}))
    // this.timeline.add(TweenMax.to(this.transitionRef, 0.5, {y: -docHeight, ease: Power3.easeInOut}))
    // this.timeline.add(TweenMax.to(this.containerRef, 0.5, {y: -docHeight, ease: Power3.easeInOut}), "-=0.5")
    // this.timeline.add(TweenMax.to(this.containerRef, 1, {y: -docHeight, ease: Power3.easeIn}))
    this.timeline.add(TweenMax.staggerTo(".item--container", 0.8, {y: -docHeight, ease: Power3.easeIn}, 0.2))
    
    items.forEach((item, i) => {
      //   new ScrollMagic.Scene({
      //     triggerElement: item,
      //     triggerHook: "onEnter"
      //   })
      //     .on("enter", () => {
      const $spans = document.querySelectorAll(`.work-item__${i} span`)
      // setTimeout(() => (
      $spans.forEach((letter, pos) => (
        this.animateText(letter, pos, $spans.length)
      ))
      // ), (i * 1000)/items.length)
      //     })
      //     .addIndicators()
      //     .addTo(this.controller)
    })
  }
  
  animateText(letter, pos, length, show = true) {
    TweenMax.fromTo(letter, show ? .6 : .3, {
      y: "100%",
      opacity: 1
    }, {
      ease: Circ.easeOut,
      delay: pos * .05,
      // startAt: show ? {y: '50%', opacity: 0} : null,
      y: '0%',
      opacity: 1
    })
  }
  
  navigateTo = (path) => {
    this.timeline.eventCallback("onComplete", () => (
      this.props.history.push(`${path}`)
    ))
    this.timeline.play()
  }
  
  componentWillUnmount() {
    console.log("killing the timeline")
    this.timeline.kill()
  }
  
  render() {
    const {innerWidth} = window
    const marginVertical = innerWidth > 768 ? "60px" : "40px"
    
    const length = workItems.length - 1
    return (
      <>
        <Breadcrumb/>
        <Menu/>
        <Container ref={(node) => this.containerRef = node}>
          {workItems.map((item, i) => (
            <ItemContainer className={"item--container"} key={i}>
              <WorkItem
                index={i}
                navigateTo={this.navigateTo}
                {...item}/>
              {i !== length && <Divider marginVertical={marginVertical}/>}
            </ItemContainer>
          ))}
        </Container>
        <TransitionOverlay ref={(node) => this.transitionRef = node}/>
      </>
    )
  }
  
}