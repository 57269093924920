import styled from "styled-components"
import {G_GRAY} from "../style/variables"
import {Link} from "react-router-dom"


export const Anchor = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${G_GRAY};
  &:visited {
    color: ${G_GRAY};
  }
`

export const CustomLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  color: ${G_GRAY};
  &:visited {
    color: ${G_GRAY};
  }
`