import React from "react"
import styled from "styled-components"
import {G_GRAY} from "../../style/variables"

const Container = styled.header`
  width: 100%;
  text-align: center;
  color: ${G_GRAY};
`

const TitleHeading = styled.h1`
  font-weight: 400;
  line-height: 1.4;
  font-size: 26px;
  @media all and (min-width: 768px) {
    font-size: 42px;
  }
`

const Caption = styled.h2`
  font-weight: 200;
  line-height: 1.4;
  font-size: 14px;
  @media all and (min-width: 768px) {
    font-size: 16px;
  }
`

const Title = (props) => (
  <Container>
    <TitleHeading>{props.title}</TitleHeading>
    <Caption>{props.caption}</Caption>
  </Container>
)

export default Title