import React, {useEffect} from "react"
import styled from "styled-components"
import {TweenMax} from "TweenMax"
import {Link} from "react-router-dom"
import {G_GRAY} from "../../style/variables"
import {WORK_PAGE_ANIMATION_DURATION} from "../../constants"

const BreadcrumbContainer = styled.nav`
  position: absolute;
  top: 30px;
  left: 25px;
  display: flex;
  color: ${G_GRAY};
  font-size: 14px;
  @media all and (min-width: 768px) {
    top: 28px;
    left: 30px;
    font-size: 20px;
  }
`

const PreviousRoute = styled(Link)`
  font-weight: 200;
  text-decoration: none;
  color: ${G_GRAY};
  padding: 5px 0;
  &:visited {
    color: ${G_GRAY};
  }
`

const CurrentRouteContainer = styled.div`
  overflow: hidden;
  display: flex;
  padding: 5px 0;
`

const Slash = styled.span`
  font-weight: 200;
  margin: 0 5px;
  opacity: 0;
  //font-size: 20px;
`

const CurrentRoute = styled.p`
  font-weight: 500;
  //font-size: 20px;
  opacity: 0;
`

const Breadcrumb = (props) => {
  
  let tween = null
  
  useEffect(() => {
    tween = TweenMax.fromTo(".current-route--container *", WORK_PAGE_ANIMATION_DURATION, {x: -200, opacity: 0}, {
      x: 0,
      opacity: 1
    })
    
    return () => {
      tween.kill()
    }
  })
  
  return (
    <BreadcrumbContainer>
      <PreviousRoute to={"/work"}>work</PreviousRoute>
      {
        props.currentRoute &&
        <CurrentRouteContainer className={"current-route--container"}>
          <Slash>/</Slash> <CurrentRoute> {props.currentRoute}</CurrentRoute>
        </CurrentRouteContainer>
      }
    </BreadcrumbContainer>
  )
}

export default Breadcrumb