export const DATA__INTERTWINE = {
  "title": "intertwine",
  "caption": "Startup / Frontend Dev. / Mobile Dev."
}

export const DATA__OTTO = {
  "title": "otto-exp",
  "caption": "Digital Experience"
}

export const DATA__WHATDIDYOUDOYESTERDAY = {
  "title": "whatdidyoudoyesterday",
  "caption": "Personal Project"
}

export const DATA__DESIGNFLOWS = {
  "title": "designflows",
  "caption": "Mobile UI/UX Design Contest"
}