import React from "react"
import styled from "styled-components"
import {CustomLink} from "../../globals/anchor"
import {G_GRAY} from "../../style/variables"

const Container = styled.footer`
  display: flex;
  align-items: center;
  width: 100%;
  //padding: 30px;
  height: 45px;
  border: 1px solid ${G_GRAY};
`

const Anchor = styled(CustomLink)`
  font-weight: 500;
  text-transform: uppercase;
  flex: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  @media all and (min-width : 768px) {
    font-size: 16px;
  }
`

const DarkAnchor = styled(Anchor)`
  background-color: ${G_GRAY};
  color: white;
  &:visited {
    color: white;
  }
`

const Footer = (props) => (
  <Container>
    <Anchor to={`/work/${props.prevRoute}`}>&lt;-{props.prevRoute}</Anchor>
    <DarkAnchor to={`/work/${props.nextRoute}`}>{props.nextRoute}-&gt;</DarkAnchor>
  </Container>
)

Footer.defaultProps = {
  prevRoute: "",
  nextRoute: "",
}

export default Footer