import React, {Component} from "react"
import Menu from "../../components/globals/menu"
import {Container, ImgContainer} from "../../components/pages/work-page/container"
import Title from "../../components/pages/work-page/title"
import Info from "../../components/pages/work-page/info"
import Breadcrumb from "../../components/pages/work-page/breadcrumb"
import Paragraph from "../../components/pages/work-page/paragraph"
import ImgSrcSet from "../../components/pages/work-page/image"
import BigLink from "../../components/pages/work-page/biglink"
import Footer from "../../components/pages/work-page/footer"
import {DATA__WHATDIDYOUDOYESTERDAY} from "./workList"
import withMountAnimation from "../../components/hoc/workPageHoc"

const infos = [
  {
    "title": "website",
    "description": "whatdidyoudoyesterday.com",
    "href": "http://whatdidyoudoyesterday.com",
    "type": "anchor"
  }, {
    "title": "role",
    "description": "design, development"
  }
]

class Whatdidyoudoyesterday extends Component {
  render() {
    return (
      <>
        <Breadcrumb currentRoute={"whatdidyoudoyesterday"}/>
        <Menu/>
        <Container>
          <Title
            title={DATA__WHATDIDYOUDOYESTERDAY["title"]}
            caption={DATA__WHATDIDYOUDOYESTERDAY["caption"]}/>
          <Info data={infos}/>
          <Paragraph
            title={"Overview"}
            body={`I just wanted to know what people did the day before, it's an aggregator of anonymous, unlimited in characters posts.<br/> Developed with Wordpress,
            with one post on hacker news, it reached more than 1.5k people resulting in 160+ posts`} />
          <ImgContainer>
            <ImgSrcSet name={"/resources/images/work/wdydy/wdydy-home"}/>
          </ImgContainer>
          <BigLink
            marginVertical={60}
            text={"read what people did yesterday"}
            link={"http://whatdidyoudoyesterday.com"}/>
          <Footer prevRoute={"otto"} nextRoute={"designflows"}/>
        </Container>
      </>
    )
  }
}

export default withMountAnimation(Whatdidyoudoyesterday)