import React from "react"
import styled from "styled-components"
import {G_GRAY, BORDER_GRAY} from "../../style/variables"

const Container = styled.section`
  display: flex;
  justify-content: center;
  margin: 50px 0;
  border-top: 1px solid ${BORDER_GRAY};
  border-bottom: 1px solid ${BORDER_GRAY};
  color: ${G_GRAY};
`

const VerticalLine = styled.span`
  width: 1px;
  align-self: stretch;
  background-color: ${BORDER_GRAY};
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const InfoContainer = styled.div`
  padding: 20px 50px;
  text-align: center;
`

const InfoTitle = styled.h2`
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 10px;
`

const InfoDescription = styled.h3`
  font-size: 14px;
  font-weight: 400;
`
const InfoAnchor = styled.a`
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  color: ${G_GRAY};
  &:visited {
     color: ${G_GRAY};
  }
`

const HorizontalLine = styled.span`
  height: 1px;
  align-self: stretch;
  background-color: ${BORDER_GRAY};
`


const Info = (props) => {
  const length = props.data.length - 1
  return (
    <Container>
      <VerticalLine/>
      <InnerContainer>
        {props.data.map((item, i) => (
          <>
            <InfoContainer key={`infoContainer__${i}`}>
              <InfoTitle key={`title__${i}`}>{item.title}</InfoTitle>
              {item.type === "anchor"
                ? <InfoAnchor target="_blank" href={item.href} key={`anchor__${i}`}>{item.description}</InfoAnchor>
                : <InfoDescription key={`desc__${i}`}>{item.description}</InfoDescription>
              }
            </InfoContainer>
            {i !== length && <HorizontalLine key={`hl__${i}`}/>}
          </>
        ))}
      </InnerContainer>
      <VerticalLine/>
    </Container>
  )
}

export default Info