import React, {Component} from "react"
import styled from "styled-components"
import withMountAnimation from "../../components/hoc/workPageHoc"
import Menu from "../../components/globals/menu"
import {Container, ImgContainer, Img100} from "../../components/pages/work-page/container"
import Title from "../../components/pages/work-page/title"
import Info from "../../components/pages/work-page/info"
import Breadcrumb from "../../components/pages/work-page/breadcrumb"
import Paragraph from "../../components/pages/work-page/paragraph"
import ImgSrcSet from "../../components/pages/work-page/image"
import {DATA__OTTO} from "./workList"
import VideoPlayer from "../../components/pages/work-page/video"
import BigLink from "../../components/pages/work-page/biglink"
import Footer from "../../components/pages/work-page/footer"

const infos = [
  {
    "title": "website",
    "description": "otto-exp.xyz",
    "href": "http://otto-exp.xyz",
    "type": "anchor"
  }, {
    "title": "role",
    "description": "design, developer, video editor"
  }
]

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  height: 300px;
  @media all and (min-width: 768px) {
    height: 500px;
  }
  @media all and (min-width: 1024px) {
    height: 90vh;
  }
`

class Otto extends Component {
  render() {
    return (
      <>
        <Breadcrumb currentRoute={"otto-exp"}/>
        <Menu/>
        <Container>
          <Title
            title={DATA__OTTO["title"]}
            caption={DATA__OTTO["caption"]}/>
          <Info data={infos}/>
          <Paragraph
            marginBottom={50}
            title={"Overview"}
            body={`Otto-exp is a digital experience designed for Massimiliano Gargano, an italian photographer.
            After creating the video with his photos for the real life exhibition, I thought that a digital experience was necessary.`}/>
          <Img100>
            <ImgSrcSet name={"/resources/images/work/otto/otto-overview"}/>
          </Img100>
          <Paragraph
            marginTop={100}
            marginBottom={50}
            title={"The Video"}
            body={`This brutalist video was projected in the real life exhibition of Massimiliano`}/>
          <VideoContainer>
            <VideoPlayer/>
          </VideoContainer>
          <Paragraph
            marginTop={100}
            marginBottom={50}
            title={"The Website"}
            body={`Brutalist as the video, the website is a digital experience and a collection of massimiliano's photography`}/>
          <ImgContainer>
            <ImgSrcSet name={"/resources/images/work/otto/otto-website"}/>
          </ImgContainer>
          <BigLink
            marginVertical={60}
            text={"you must see this, click here"}
            link={"http://otto-exp.xyz"}/>
          <Footer prevRoute={"intertwine"} nextRoute={"whatdidyoudoyesterday"}/>
        </Container>
      </>
    )
  }
}

export default withMountAnimation(Otto)