import React, {Component} from "react"
import styled from "styled-components"
import Menu from "../components/globals/menu"
import {G_GRAY} from "../components/style/variables"
import {ThinHeading1} from "../components/globals/typography"
import {Linear} from "TweenLite"
import {TweenMax} from "TweenMax"

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Line = styled.div`
  // width: 1px;
  // background-color: ${G_GRAY};
  // align-self: stretch;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  background-color: white;
  z-index: 2;
`

const LeftLine = styled(Line)`
  //margin-right: 60px;
  left: 0;
  border-right: 1px solid black;
`

const RightLine = styled(Line)`
  //margin-left: 60px;
  right: 0;
  border-left: 1px solid black;
`

const CopyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 1000px;
  padding: 0 20px;
  height: 100%;
  text-align: justify;
`

const Copy = styled(ThinHeading1)`
  position: relative;
  color: ${G_GRAY};
  mix-blend-mode: difference;
  font-size: 28px;
  z-index: 1;
  max-width: 900px;
  @media all and (min-width : 768px) {
    font-size: 65px;
  }
`

const ExternalLink = styled.a`
  font-weight: 500;
  color: ${G_GRAY};
  text-decoration: underline;
  &:visited {
    color: ${G_GRAY};
  }
`

export default class About extends Component {
  
  componentDidMount() {
    this.tween = TweenMax.to(".line", 1, {width: 0, ease: Linear.easeNone})
    // TweenMax.to(this.leftLine.current, 2, {width: 0, ease: Linear.easeNone})
    // TweenMax.to(this.rightLine.current, 2, {width: 0, ease: Linear.easeNone})
  }
  
  componentWillUnmount() {
    this.tween.kill()
  }
  
  render() {
    return (
      <>
        <Menu/>
        <Container>
          <CopyContainer>
            <LeftLine className={"line"}/>
            <Copy>
              You can find me on <ExternalLink
              href={"https://www.linkedin.com/in/stimparato/"}>Linkedin</ExternalLink>, <ExternalLink
              href={"https://github.com/steoo"}>Github</ExternalLink>, <ExternalLink
              href={"https://www.behance.net/essei"}>Behance</ExternalLink>.
              <br/>
              <br/>
              Or send me an <ExternalLink href={"mailto:st.imparato@gmail.com"}>email</ExternalLink> [at]
              <br/>
              st.imparato@gmail.com
            </Copy>
            <RightLine className={"line"}/>
          </CopyContainer>
        </Container>
      </>
    )
  }
  
}