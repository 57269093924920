import React from "react"

const DynamicPageLoader = ({match}) => {
  const Component = require(`../../pages/work/${match.params.id}`).default
  
  return (
    <Component/>
  )
}

export default DynamicPageLoader