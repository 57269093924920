import styled from "styled-components"
import {G_GRAY} from "../style/variables"

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${props => props.marginVertical ? `${props.marginVertical} 0` : "30px 0"};
  //margin-top: ${props => props.marginVertical ? `${props.marginVertical}` : "30px"};
  //margin-bottom: ${props => props.marginVertical ? `${props.marginVertical}` : "30px"};
  background-color: ${G_GRAY};
`

export default Divider