import React, { Component } from 'react';
import Modal from "react-modal"
import {BrowserRouter as Router, Route} from "react-router-dom"
import Home from "./pages/Home"
import Work from "./pages/Work"
import About from "./pages/About"
import DynamicPageLoader from "./components/pages/DynamicPageLoader"
import ScrollToTop from "./components/globals/scrollToTop"

Modal.setAppElement('#root')

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <Route path="/" exact component={Home}/>
          <Route path="/work" exact component={Work}/>
          <Route path="/work/:id" exact component={DynamicPageLoader}/>
          <Route path="/about" exact component={About}/>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
