import React, {Component} from "react"
import styled from "styled-components"
import Modal from "react-modal"
import MenuPage from "../../pages/MenuPage"
import {G_GRAY} from "../style/variables"

Modal.defaultStyles.overlay.backgroundColor = 'white'
Modal.defaultStyles.overlay.zIndex = 10

const customStyle = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: "white"
}

Modal.defaultStyles.content.border = 0
Modal.defaultStyles.content = {
  ...Modal.defaultStyles.content,
  ...customStyle,
  border: 0,
  padding: 0
}

const CircleButton = styled.button`
  position: fixed;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  outline: 0;
  background-color: ${G_GRAY};
  border: 0;
  z-index: 10;
`

export default class Menu extends Component {
  
  state = {
    isOpened: false
  }
  
  openModal = () => (
    this.setState({isOpened: true})
  )
  
  closeModal = () => (
    this.setState({isOpened: false})
  )
  
  render() {
    return (
      <>
        <CircleButton
          onClick={this.openModal}
        />
        <Modal
          style={customStyle}
          isOpen={this.state.isOpened}>
          <MenuPage
            closeModal={this.closeModal}/>
        </Modal>
      </>
    )
  }
  
}