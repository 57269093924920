import React from "react"
import styled from "styled-components"
import {SHADOW_GRAY} from "../../style/variables"

const DivContainer = styled.div`
  margin-top: 100px;
  opacity: 0;
`

export const Container = (props) => (
  <DivContainer className={"work--container"}>{props.children}</DivContainer>
)

export const BaseImgContainer = styled.div`
  margin: 50px auto 40px;
  text-align: center;
  border-radius: 2px;
  img {
    width: 100%;
  }
`

export const ImgContainer = styled(BaseImgContainer)`
  @media (min-width: 992px) {
    max-width: 900px;
  }
  //@media (min-width: 1281px) {
  //  max-width: 900px;
  //}
  @media (min-width: 1441px) {
    max-width: 1440px;
  }
  img {
    border-radius: 5px;
    box-shadow: 0 0 10px 2px ${SHADOW_GRAY};
  }
`

export const Img100 = styled.div`
  width: 100%;
  img {
    width: 100%;
    box-shadow: 0 0 10px 2px ${SHADOW_GRAY};
  }
`