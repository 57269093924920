import React, {Component} from "react"
import styled from "styled-components"
import Menu from "../../components/globals/menu"
import {Container, BaseImgContainer, Img100} from "../../components/pages/work-page/container"
import Title from "../../components/pages/work-page/title"
import Info from "../../components/pages/work-page/info"
import Breadcrumb from "../../components/pages/work-page/breadcrumb"
import Paragraph from "../../components/pages/work-page/paragraph"
import ImgSrcSet from "../../components/pages/work-page/image"
import Footer from "../../components/pages/work-page/footer"
import {DATA__DESIGNFLOWS} from "./workList"
import withMountAnimation from "../../components/hoc/workPageHoc"

const infos = [
  {
    "title": "website",
    "description": "designflows.it",
    "href": "https://designflows.it",
    "type": "anchor"
  }, {
    "title": "role",
    "description": "Mobile UI/UX Designer"
  }
]

const FixedImageContainer = styled(BaseImgContainer)`
  max-width: 250px;
`

class Designflows extends Component {
  render() {
    return (
      <>
        <Breadcrumb currentRoute={"designflows"}/>
        <Menu/>
        <Container>
          <Title
            title={DATA__DESIGNFLOWS["title"]}
            caption={DATA__DESIGNFLOWS["caption"]}
          />
          <Info data={infos}/>
          <Paragraph
            marginBottom={50}
            title={"Overview"}
            body={`Designflows is a contest for designers; the brief was to design 3 screens of an application for classical music newbies and aficionados who wants to learn and discover music.`}/>
          <Img100>
            <ImgSrcSet name={"/resources/images/work/designflows/designflows-overview"}/>
          </Img100>
          <Paragraph
            marginTop={100}
            marginBottom={50}
            title={"Discover"}
            body={`First of 3 screens, this is the first screen shown to the user when opens the application`}/>
          <FixedImageContainer>
            <ImgSrcSet name={"/resources/images/work/designflows/discover"}/>
          </FixedImageContainer>
          <Paragraph
            marginTop={100}
            marginBottom={50}
            title={"Learn"}
            body={`Second of 3 screens, this is the screen where users can learn classical music`}/>
          <FixedImageContainer>
            <ImgSrcSet name={"/resources/images/work/designflows/learn"}/>
          </FixedImageContainer>
          <Paragraph
            marginTop={100}
            marginBottom={50}
            title={"Paywall"}
            body={`Third of 3 screens, this is the screen where the users can subscribe to access premium content`}/>
          <FixedImageContainer>
            <ImgSrcSet name={"/resources/images/work/designflows/paywall"}/>
          </FixedImageContainer>
          <Footer prevRoute={"whatdidyoudoyesterday"} nextRoute={"intertwine"}/>
        </Container>
      </>
    )
  }
}

export default withMountAnimation(Designflows)