import styled from "styled-components"

export const ThinHeading1 = styled.h1`
  font-weight: 200;
`

export const MediumSpan = styled.span`
  font-weight: 500;
`

export const UnderlinedSpan = styled.span`
  text-decoration: underline;
`
