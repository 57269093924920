import React, {Component} from "react"
import styled from "styled-components"
import {Linear} from "TweenLite"
import {TimelineMax} from "TweenMax"
import {G_GRAY} from "../../style/variables"

const Rectangle = styled.div`
  position: absolute;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  background-color: ${G_GRAY};
  width: ${props => props.width || 850}px;
  height: 400px;
  z-index: 0;
`

const DURATION = 20


class Rect extends Component {
  
  constructor(props) {
    super(props)
    
    this.rectangle = React.createRef()
    this.timeline = new TimelineMax({repeat: -1})
    // this.timeline = new TimelineMax()
  }
  
  componentDidMount() {
    this.timeline
      .to(this.rectangle.current, DURATION, {
        height: 1,
        rotation: "360",
        ease: Linear.easeNone
      })
      .to(this.rectangle.current, DURATION, {
        height: 400,
        rotation: "0_cw",
        ease: Linear.easeNone
      })
  }
  
  render() {
    const {innerHeight} = window
    return (
      <Rectangle
        ref={this.rectangle}
        width={innerHeight + 100}
      />
    )
  }
  
}

export default Rect